import React from 'react'
import { graphql } from 'gatsby'
import Layout from '@/components/Layout/Layout'
import SearchPage from '@/components/SearchPage'

interface SearchData {
  data: {
    wp: any
    page: any
    mainMenu: any
    form: any
  }
  location: any
}

const Search = ({ data, location }: SearchData) => {
  const { mainMenu } = data
  const { wp } = data
  const { form } = data
  const langData = { languages: wp.languages, currentLang: 'en', translations: [] }
  const { searchResults }: any = data

  return (
    <Layout nav={mainMenu} lang={langData} showhero={false} form={form}>
      <SearchPage data={searchResults.nodes} location={location} lang={'en'} />
    </Layout>
  )
}

export const SearchQuery = graphql`
  query searchEn {
    locales: allLocale(filter: { language: { eq: "en" } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    searchResults: allLocalSearchPagesEn {
      nodes {
        index
        store
      }
    }
    wp {
      ...allLang
    }
    form: wpGravityFormsForm(formId: { eq: 2 }) {
      ...GravityFormFields
    }
    mainMenu: wpMenu(locations: { eq: MAIN_MENU___EN }) {
      ...navigation
    }
  }
`

export default Search
